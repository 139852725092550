import axios from "axios";

axios.defaults.withCredentials = true;

export const baseURL = process.env.REACT_APP_API_URL;
const botId = process.env.REACT_APP_BOT_ID;

const axiosInstance = axios.create({
  baseURL,
  data: {},
  withCredentials: true,
});

const axiosNoTokenInstance = axios.create({
  baseURL,
  data: {},
});

axiosNoTokenInstance.interceptors.request.use((config) => {
  config.params = {
    botId,
    ...config.params,
  };
  return config;
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.params = {
    botId,
    ...config.params,
  };
  config.headers = { Authorization: token ? `Bearer ${token}` : "" };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newTokens = await refreshAccessToken();
        localStorage.setItem("token", newTokens.accessToken);
        localStorage.setItem("refreshToken", newTokens.refreshToken);
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${newTokens.accessToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        console.error("Token refresh failed:", err);
        logout();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await axiosNoTokenInstance.post("/sso/refresh-token", {
      refreshToken,
    });
    return response.data; // Return the new access and refresh tokens
  } catch (error) {
    throw new Error("Failed to refresh token");
  }
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
};

// axiosNoTokenInstance.interceptors.request.use(request => {
//   console.log('Starting Request', JSON.stringify(request, null, 2))
//   return request
// })

// axiosNoTokenInstance.interceptors.response.use(response => {
//   console.log('Response:', JSON.stringify(response, null, 2))
//   return response
// })

export { axiosInstance, axiosNoTokenInstance };
